@import "variables";

/*Login Start*/
.login-page{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../images/cric-wall-new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  .login-form{
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background: $white-color;
    h2{
      font-weight: bold;
      color: $bottle-green;
    }
    .input-style {
      border-radius: 5px !important;
      border: 1px solid $bottle-green;
      box-shadow: none;
      padding: 25px 10px !important;
      margin-bottom: 10px;
      margin-top: 5px;
    }

    button{
      font-weight: bold;
      background: $bottle-green;
      padding: 12px 10px;
      border: 1px solid $bottle-green;
      margin-top: 5px;
    }
    p{
      color: $bottle-green;
      font-weight: bold;
    }
  }

}
/*Login End*/
