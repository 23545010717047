body, html {
  height: 100%;
  width: 100%;
  font-family: "Courier", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

.theme-dark {
  background: $theme-dark;
}

.dark-text {
  color: $theme-dark;
}

.text-shy{
  color: $gray-color;
}

.text-bot-green {
  color: $bottle-green;
}

.bg-bot-green{
  background: $bottle-green;
}

.fa-eye, .fa-trash {
  color: $icon-color;
}

.fa-sort {
  color: $white-color;
}

.fa-circle, .fa-circle-o {
  color: $bottle-green;
}

.shadow-2xl {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.5);
}

.box-shadow-non {
  box-shadow: none !important;
}

.out-non{
  outline: none !important;
}

.v-middle{
  vertical-align: middle;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: $extra-small-font;
}

.f-14 {
  font-size: $small-font;
}

.f-18 {
  font-size: $medium-font;
}

.side-wrapper {
  background: $theme-dark;
  position: fixed;
  width: 250px;
  height: 100vh;
  overflow: auto;

  .menu-list {
    .active {
      background: $bottle-green;
      color: $white-color;
    }

    li {
      font-size: $small-font;
      padding: 15px;
      color: $white-color;

      a {
        color: $white-color;
        text-decoration: none;
      }

      &:hover {
        background: $bottle-green;
        color: $white-color;
      }
    }
  }
}

.main-wrapper {
  position: relative;
  width: calc(100% - 250px);
  margin-left: 250px;
  min-height: 100vh;
  overflow: auto;
  z-index: 0;

  .header-main {
    background: $white-color;
    position: fixed;
    margin-left: 250px;
    top: 0;
    width: calc(100% - 250px);
    left: 0;
    z-index: 1;

    .disabled-link {
      pointer-events: none;
      text-decoration: none;
      color: $black-color;
    }
  }

  .header-nav {
    background: #d9e4eb;
    z-index: 1;

    li {
      color: $black-color;
    }

    .active {
      color: #8e9092;
      text-decoration: none;
    }
  }

  .main-content {
    padding-top: 150px;
    padding-bottom: 50px;
  }

  ::placeholder {
    color: #c8c8c8;
    font-size: $small-font;
  }

  .search-main {
    border-color: $bottle-green;
    border-radius: 2px 0 0 2px;
  }

  .search-btn {
    border-color: $bottle-green;
    background: $bottle-green;
    border-radius: 0 2px 2px 0;
  }

  .okBtn{
  margin-top: 48px !important;
}

  .common-btn {
    border-color: $bottle-green;
    background: $bottle-green;
    border-radius: 3px;
  }

  .main-table {
    z-index: 0;

    overflow: auto;

    td {
      font-size: $small-font;
    }

    thead tr th {
      background: $theme-dark;
      position: sticky;
      z-index: 100;
      top: 0;
      white-space: nowrap;
    }
  }

  .full-table {
    max-height: calc(100vh - 300px);
  }

  .inner-table {
    max-height: 300px;
  }

  .create-main {
    height: calc(100vh - 200px);

    z-index: 0;

    h3 {
      font-weight: bold;

      &:after {
        content: '';
        position: absolute;
        left: 15px;
        bottom: 0;
        height: 3px;
        width: 80px;
        background: $bottle-green;
      }
    }
    .custom-date {
      background: url("../images/calendar.svg");
      background-repeat: no-repeat;
      background-position: 98%;
    }
    .create-team-main{
      /*Checkbox Style Start*/
      .chekbox {
        display: block;
        position: relative;
        margin-bottom: 12px;
        padding-right: 50px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      .chekbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 25px;
        width: 25px;
        right: 0;
        left: 100%; /* Ugly hack because it's been set to left: 0 by default */
        z-index: 1;
        top: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        left: 100%; /* Ugly hack because it's been set to left: 0 by default */
        height: 25px;
        width: 25px;
        border: 1px solid $bottle-green;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .chekbox input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .chekbox .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid $bottle-green;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      /*Checkbox Style End*/
    }

    .create-policy-main{
      .create-policy-main-right{
        input{
          height: 40px;
        }
      }

      /*Checkbox Style Start*/
      .box {
        display: block;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 20px;
        width: 20px;
      }

      /* Hide the browser's default checkbox */
      .box input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        border: 1px solid $bottle-green;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .box input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .box .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid $bottle-green;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      /*Checkbox Style End*/
    }
  }

  .bulk-main-suc-fail {
    position: relative;
    max-height: 300px;
    overflow: auto;

    thead tr th {
      background: $white-color;
      position: sticky;
      z-index: 100;
      top: 0;
    }

    tbody {
      td {
        vertical-align: middle;
      }
    }

    .input-table {
      overflow: auto;
      input {
        width: 100%;
        border: 1px solid #d8d8d8;
        padding: 5px;

        &:focus {
          outline: none;
        }
      }
    }

    .csv-table {
      overflow: auto;

      td {
        input {
          width: 100%;
          border: 1px solid $bottle-green;
          padding: 5px;

          &:focus {
            outline: none;
          }
        }
        .fail{
          border: 1px solid #dc3545 !important;
        }
      }
    }


  }

  .label-style {
    font-size: 16px;
    font-weight: bold;
    color: $theme-dark;
  }

  .input-style {
    border: 1px solid $bottle-green;
    box-shadow: none;
  }

  .select-style-player {
    height: 38px;
    width: 100%;
    background: transparent;
    border: 1px solid $bottle-green;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    padding: 0 10px;
    color: $theme-dark;
    background-image: url("../images/arrow.svg");
    background-position: 98%;
    background-repeat: no-repeat;
  }

  .select-style {
    height: 38px;
    width: 100%;
    background: transparent;
    border: 1px solid $bottle-green;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    padding: 0 10px;
    color: $theme-dark;
    background-image: url("../images/arrow.svg");
    background-position: 98%;
    background-repeat: no-repeat;
  }

  .bg-pos {
    background-position: 90% !important;
  }

  .border-btn {
    width: 170px !important;
    padding: 7px 0 !important;
    color: $bottle-green !important;
    border: 1px solid $bottle-green !important;
  }

  .fill-btn {
    width: 170px;
    padding: 7px 0;
    color: $white-color;
    background: $bottle-green;
    border: 1px solid $bottle-green;
  }

  .bottom-btn {
    position: absolute;
    bottom: 0;
  }

  .upload-input {
    position: absolute;
    opacity: 0;
    width: 38px;
    height: 38px;
    cursor: pointer;
  }

  .upload-input-bulk {
    position: absolute;
    opacity: 0;
    width: 170px;
    height: 40px;
    cursor: pointer;
  }

  .upload-btn {
    height: 40px;
    width: 40px;
    background: $white-color;
    border: 1px solid $bottle-green;

    i {
      color: $theme-dark;
      font-size: 30px;
    }
  }
}

.loader-main {
  background: $white-color;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  left: 0;
  opacity: .80;
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    position: absolute;
    margin: -#{$scale/2} 0 0 -#{$scale/2};
    border: #{$scale*0.1} solid $bottle-green;
    box-sizing: border-box;
    overflow: hidden;
    width: $scale;
    height: $scale;
    left: 50%;
    top: 50%;
    animation: loader-spin $speed linear infinite reverse;
    box-shadow: 0 0 0 1px $bottle-green inset;

    &:before {
      content: "";
      position: absolute;
      animation: loader-spin $speed cubic-bezier(.59, .25, .4, .69) infinite;
      background: $bottle-green;
      transform-origin: top center;
      border-radius: 50%;
      width: 150%;
      height: 150%;
      top: 50%;
      left: -12.5%;
    }
  }
}

/* Scrollbar CSS Start */
::-webkit-scrollbar {
  width: 5px;
  left: 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $bottle-green;
  border-radius: 10px;
}

/* Scrollbar CSS End */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropdown-menu{
  height: 40px;
  left: -125px !important;
  width: 300px;
}

.dropdown-toggle::after{
  display: none;
}

.dropdown-menu-player{
  height: 40px;
  left: -125px !important;
  width: 300px;
}

.search-popup{
  width: 300px;
  max-height: 180px;
  overflow: auto;
  border: 1px solid $bottle-green;
}

/*Evalution Css Start*/
.evalution-box{
  border: 1px solid $bottle-green;

  .evalution-main {
    display: block;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .evalution-main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid $bottle-green;
  }

  /* When the checkbox is checked, add a blue background */
  .evalution-main input:checked ~ .checkmark {
    background-color: $bottle-green;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .evalution-main input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .evalution-main .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
/*Evalution Css End*/

.edit-season{
  overflow: auto;

  .season-table {
    overflow: auto;
    td{
      padding: 0 5px;
    }
    input {
      width: 100%;
      border: 1px solid $bottle-green;
      padding: 5px;

      &:focus {
        outline: none;
      }
    }
  }
}

.formDisplay {
  margin: 0px;
  padding: 0px;
  display: none;
  background-color: $white-color;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  left: 140px;
  top: 40px;
  z-index: 1;
}
.form-controls{
  width: 160px;
  border: 1px solid black;
}
.closeButtonStyle {
  padding-top: 0px;
  border: none;
  position: relative;
  top: 0px;
  right: 0px;
}
.custom-date {
  padding: 0px;
  font-size: 13px;
  background: url("../images/calendar.svg");
  background-repeat: no-repeat;
  background-position: 98%;
}
.checkbox-filter {
  height: 25px;
  width: 25px;
  background-color: $white-color;
}
.merge-players-content {
  background-color: $white-color;
  position: absolute;
  min-width:60rem;
  transform: translate3d(-1px, 66px, 0px);
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;
}

.text-white-color {
  color: white !important;
}

.small-input {
  input {
    width: 40px;
    margin: 0 10px;
  }
}


/*Governing Entity Start*/
/* The container */
.container-radio {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark-radio {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark-radio {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark-radio {
  background-color: #cccccc;
  border: 1px solid #336600;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark-radio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark-radio:after {
  top: 6px;
  left: 6px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #336600;
}
/*Governing Entity End*/