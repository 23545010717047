$bottle-green: #336600;
$theme-dark: #304350;
$icon-color: #616161;
$white-color: #ffffff;
$black-color: #000000;
$gray-color: #a2a2a2;

$extra-small-font: 12px;
$small-font: 14px;
$medium-font: 18px;

$scale: 100px;
$speed: 2s;
$angle: 25;
