.table {
  /*Checkbox Style Start*/
  .chekbox {
    display: block;
    position: relative;
    margin-bottom: 26px;
    padding-right: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .chekbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    right: 0;
    z-index: 1;
    top: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    border: 1px solid $bottle-green;
    background: $white-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .chekbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .chekbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $bottle-green;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  /*Checkbox Style End*/

  .drop {
    height: 75px;
    left: 0 !important;
    width: 0 !important;
    top: 10px !important;
  }
}

.high{
  color: #336600 !important;
  border-bottom: 2px solid #336600;

}

.system-config-tab {
  li {
    padding: 5px;
  }

  a {
    margin-left: 10px;
    color: black !important;
  }
}


#myTabContent {
  .active {
    background-color: #e2e5df !important;
    color: #336600;
    border: 0px
  }

  border: 0px;
  color: #336600;
}

#arrow-sys {
  background-color: #336600 !important;
  border-radius: 0;
}

.nav-item:active{
  //border-bottom: 2px solid #f60808;
  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100px;
    height: 3px;
    background: #336600;
  }
}

.notification-header-label {
  //border-bottom: 2px solid #336600;
  font-size: 23px;
  font-weight: bold;
  color: #336600;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 3px;
    background: #336600;
  }
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  height: 25px;
  width: 25px;
  padding: 4px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #336600;
  background-color: #e8eee3;
  border-radius: 50%;

  &:focus {
    outline: none;
  }
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #336600;
}

/* optional styles, I'm using this for centering radiobuttons */
.flex {
  display: flex;
  align-items: center;
}

#radio1, #radio2 {
  margin: 14px 10px -5px 0;
}

.back-icon-setting {
  //position: relative;
  //bottom:73px;
  //right: 48px;
  color: #336600;
  background-color: #ffffff;
  position: relative;
  margin-top: 0px;
  bottom: 71px;
}

.back-btn-settings {
  margin: 0px 4px 4px 21px;
  font-size: 19px;
}

/* The checkbox-container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 20px;
  //border-radius: 50%;
  width: 20px;
  background-color: #f4f6f3;
  border: 1px solid #336600;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #cccccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #336600;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.list-group-item {
  color: #336600;

  &:hover {
    background-color: #e5e5dd !important;
    outline: #a0ff9c;
  }
}

.list-group-item a:hover, a:visited, a:link, a:active {
  text-decoration: none !important;;
}

.dead-lift {
  border-bottom: 1px solid #336600;
  border-left: 1px solid #336600;
  border-top: 1px solid #8e9092;
  border-right: 1px solid #8e9092;
  font-weight: lighter;
  height: 40px;
  width: 40px;
  padding: 7px 0px 0px 6px;
  margin-right: 8px;
  color: black;

  &:focus {
    outline: none;
  }
}

.pt-200{
  padding-top:180px;
}

.errordialog {
  .clsmodal{
    max-width:1024px;
  }
  .error_box {
    max-width: 100%;
    .clsgroupicon{
      position: absolute;
      left:0;
      top:20px;
    }
    .clscloseicon{
      position: absolute;
      right:0;
      top:2px;
      text-align: right;
      .no_btn{
        background: none !important;
        border:none !important;
        box-shadow:none !important;
      }
    }
    .clswarntext{
      position: absolute;
      left:70px;
      top:26px;
      p {
        font-size: 24px;
        font-weight: normal;
        font-family: 'SF Pro Text';
      }
    }
    .clswarnrow{
      padding-bottom: 10%;
    }
    .clsheadrow{
      div div {
          font-family: 'SF Pro Text';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.41px;
      }
      .match-players {
         font-family: 'SF Pro Text';
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.41px;
        tr{
          cursor: pointer;
        }
        th{
          font-size: 12px;
        } 
        td{
          font-size: 14px;
        }
        .odd_row{
          background: #f2f2f2;
        }
       }
    }
    .fill-btn{
      width: 200px !important;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0)
and (min-resolution:.001dpcm) {
.custom-input-date {
  background: url("../images/calendar.svg");
  background-repeat: no-repeat;
  background-position: 98%;
  width: 145px !important;
}
}

@-moz-document url-prefix() {
  .custom-input-date {
    background: none;
    width: 145px !important;
  }
}

.upload-season{
  width: 111px !important;
}